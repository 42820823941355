export const getAriaMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-aria-route.jpg'),
            mapLine: 'AriaPath',
            venueBg:  require('@/assets/maps/aria.png'),
            venue: 'Aria',
            distance: '1.7',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
